import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Navigation from './Components/Navigation/Navigation';
// import CharacterPage from './Components/CharacterPage/CharacterPage';
import ComponentPage from './Components/ComponentsPage/ComponentPage';
import HomePage from './Components/HomePage/HomePage';

import CharsPageList from './Components/CharsPage/CharsPageList';
import CharsPageGrid from './Components/CharsPage/CharsPageGrid';

import allCharacters from './Storage/characters.json';
import allComponents from './Storage/components.json';

import { setSelected, changePageView } from './actions';
// import CharsPageListChapter from './Components/CharsPage/CharsPageListChapter';

const mapStateToProps = state => {
    return {
        selectedComp: state.selectComp.selectedComp,
        charsPageView: state.changePageView.charsPageView
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectedChange: (payload) => {
            // console.log(event);
            dispatch(setSelected(parseInt(payload)))
        },
        onViewChange: () => {
            dispatch(changePageView())
        }
    }
}

class App extends Component {
    render() {
        const { selectedComp, onSelectedChange, charsPageView, onViewChange } = this.props;

        const filteredChars = allCharacters.characters.filter(
            char => {
                return (char.components.traditional.includes(selectedComp))
            }
        )

        const applicableChapters = filteredChars.map(item =>
            item.strokes.traditional
        );

        const filteredChapters = [...new Set(applicableChapters.flat(1))].sort((a, b) => a - b);

        // console.log(filteredChapters);
        const selectedCompDisplay = allComponents.components.find(str => str.id === selectedComp);

        const applicableComps = filteredChars.map(item => {
            // console.log('applicableComps', item);
            return (item.components.traditional);
        });

        const filteredComps = [...new Set(applicableComps.flat(1))];

        // console.log('render', applicableComps);

        const characterComponents = allComponents.components.filter(comp => {
            return (
                filteredComps.includes(comp.id)
            )
        });

        // console.log('charComps', characterComponents);

        const totalComps = allComponents.components.length;

        const totalChars = allCharacters.characters.length;

        return (
            <div className="App">
                <Router>
                    <Navigation selectedComponent={selectedCompDisplay.display} />
                    <Switch>
                        <Route path="/list-components">
                            <ComponentPage
                                allComponents={allComponents}
                                allCharacters={allCharacters}
                                onComponentSelect={onSelectedChange}
                            />
                        </Route>

                        <Route path="/selected">
                            {charsPageView === 0 ? (
                                <CharsPageList
                                    usedComponents={characterComponents}
                                    usedCharacters={filteredChars}
                                    usedStrokes={filteredChapters}
                                    onComponentSelect={onSelectedChange}
                                    charsPageView={charsPageView}
                                    onViewChange={onViewChange}
                                />) : (
                                    <CharsPageGrid
                                        usedCharacters={filteredChars}
                                        charsPageView={charsPageView}
                                        onViewChange={onViewChange}
                                    />
                                )}
                        </Route>

                        <Route path="/">
                            <HomePage
                                totalComps={totalComps}
                                totalChars={totalChars}
                            />
                        </Route>
                    </Switch>
                </Router>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
import React from 'react';
import './Component.css';
import { NavLink } from 'react-router-dom';

const Component = ({ id, displayed, onComponentSelect }) => {
    // return (id === 305 || id === 702) ? (
    //     <NavLink className='dib br3 pa3  f4 ma2 grow bw2 shadow-5'
    //         style={{
    //             backgroundColor: '#c30000',
    //             color: '#334866',
    //             textDecoration: 'none'
    //         }}
    //         to='/selected'
    //         onClick={() => onComponentSelect(id)}>{displayed}</NavLink>
    // ) :
    return(
            <NavLink className='dib br3 pa3  f4 ma2 grow bw2 shadow-5'
                style={{
                    backgroundColor: '#ECE9DA',
                    color: '#334866',
                    textDecoration: 'none'
                }}
                to='/selected'
                onClick={() => onComponentSelect(id)}>{displayed}</NavLink>
        )

}

export default Component;
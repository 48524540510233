import React from 'react';
import CharsPageOpts from './CharsPageOpts';
import CharsPageListChapter from './CharsPageListChapter';

const CharsPageList = ({
    usedComponents,
    usedCharacters,
    usedStrokes,
    onComponentSelect,
    onViewChange,
    charsPageView
     }) => {
    // returns subset of characters used for defined stroke number
    const getCharsByStrokeNo = (str) => {
        return (usedCharacters.filter(char => char.strokes.traditional === str));
    }
    // returns an array of components, which are present in array of characters (input)
    const getCompsByChars = (chars) => {
        const getComps = [...new Set(chars.map(item => item.components.traditional).flat(1))];
        return (
            usedComponents.filter((item) => {
                return (getComps.includes(item.id));
            })
        )
    }
    // returns an array of CharacterChapter objects for each stroke number (=chapter)

    const chaptersArray = usedStrokes.map((i) => {
        return (
            <CharsPageListChapter
                key={i}
                strokeNo={i}
                usedCharacters={getCharsByStrokeNo(i)}
                usedComponents={getCompsByChars(getCharsByStrokeNo(i))}
                onComponentSelect={onComponentSelect}
            />
        )
    })
    
    // returns the page
    return (
        <div>
            <CharsPageOpts
                usedCharacters={usedCharacters}
                onViewChange={onViewChange}
                charsPageView={charsPageView}
            />
            <div>
                {chaptersArray}
            </div>
        </div>
    ) 
}

export default CharsPageList;
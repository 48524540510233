import React from 'react';

const HomePage = ({
    totalComps,
    totalChars
}) => {
    return (
        <div className='pt2 pl2'>
            <p className='b'>{'What is this page about?'}</p>
            <p>{'Whenever I was learning some new Chinese characters, I ran into situations, where the newly learned character was very similar to ones I saw or learned previously. Hence, I started making lists of similarly looking characters, which contained the same "component". These "components" were simply chunks of characters, not limited to Kangxi radicals and definitely not based on any linguistic or etymological research.'}</p>
            <p>{'Anyhow, listing these characters helped me learn and remember the differences. I decided to create this page mostly for my own reference + to improve on my React coding, but I hope someone else finds it useful.'} </p>
            <p className='b'>{'How to use this page?'}</p>
            <p>{'Simply, in the Components tab, select any component. This will take you to the list of characters, where that component is present'}</p>
            <p>{'In the Selected tab, you can scroll through the characters.'}</p>
            <p className='b'>{'Why cannot I find X character?'}</p>
            <p>{'I am slowly updating the database of characters, but it is a rather slow and manual process. First target would be the top 1-2k of most frequent characters. There are currently '}{totalChars}{' unique characters and '}{totalComps}{' unique components.'}</p>
            <p className='b'>{'Where can I get more information?'}</p>
            <p>{'In case you are interested in any part of the project, shoot me a message on components@rbl.sk'}</p>
        </div>
    )
}

export default HomePage;
import React from 'react';
import ComponentChapter from './ComponentChapter';
import ComponentList from './ComponentList';

const ComponentPage = ({
    allComponents,
    allCharacters,
    onComponentSelect,
 }) => {
    // return all components with specific stroke number
    const getCompsByStrokeNo = (str) => {
        return (
            allComponents.components.filter(item => {
                return (item.strokes === str);
            })
        );
    }
    // return all different stroke numbers present for components
    const allStrokes = allComponents.components.map(item =>
        item.strokes
    );
    // flatten the array
    const usedStrokes = [...new Set(allStrokes.flat(1))].sort((a, b) => a - b);
    // return an array of ComponentChapters
    const chaptersArray = usedStrokes.map((i) => {
        return (
            <ComponentChapter
                key={i}
                strokeNo={i}
                usedComponents={getCompsByStrokeNo(i)}
                onComponentSelect={onComponentSelect}
            />
        )
    })
    // identify, which components have a large number of characters associated with them
    const keys = allComponents.components.map(item => item.id);
    const values = allComponents.components.map(comp => {
        return allCharacters.characters.map(char => {
            return (char.components.traditional.includes(comp.id));
        }).filter(v => v).length
    });
    // keep only those, which have at least X characters
    const topCompsFilter = Object.keys(Object.fromEntries(keys.map((_, i) => [keys[i], values[i]])
        .filter(item => item[1] > 10)))
        .map(item => parseInt(item));

    const topComps = allComponents.components.filter(item => topCompsFilter.includes(item.id));
    
    // make a chapter out of those components
    const topCompsArray =
        <ComponentList
            key={"topCompsArray"}
            usedComponents={topComps}
            onComponentSelect={onComponentSelect}
        />


    // return div, if list (0), then list of chapters, if grid (1), then component list directly
    return (
        <div>
            <div>
                <p className='pl2 pt2 mb0 f4'>{'Top components'}</p>
                {topCompsArray}
            </div>
            {chaptersArray}
        </div>
    ) 
}

export default ComponentPage;
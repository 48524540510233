import React from 'react';
import CharsPageOpts from './CharsPageOpts';

const CharacterPage = ({
    usedCharacters,
    onViewChange,
    charsPageView }) => {
    //returns an array of alternate character divs, in case grid is selected
    const charsArray = usedCharacters.map((i) => {
        return (
            <div className="Chars-Char"
            key={i.character.traditional}>
                {i.character.traditional}
            </div>
        )
    })
    // returns the page
    return (
        <div>
        <CharsPageOpts
            usedCharacters={usedCharacters}
            onViewChange={onViewChange}
            charsPageView={charsPageView}
        />
        <div className="CharsGrid">
            {charsArray}
        </div>
    </div>
        )
}

export default CharacterPage;
import React from 'react';

const ComponentListTitle = ({ strokeNo }) => {
    return (
        <div>
            <p className='pl2 pt2 mb0 f4'>{strokeNo}{' strokes'}</p>
        </div>
    )
}

export default ComponentListTitle;
import {
    SELECT_COMPONENT,
    CHANGE_PAGE_VIEW
} from './constants';

const initialStateComp = {
    selectedComp: 305
}

export const selectComp = (state = initialStateComp, action = {}) => {
    switch (action.type) {
        case SELECT_COMPONENT:
            return Object.assign({}, state, { selectedComp: action.payload });
        default:
            return state;
    }
}

const initialStateCharsPageView = {
    charsPageView: 0
    // 0 = list
    // 1 = grid
}

export const changePageView = (state = initialStateCharsPageView, action = {}) => {
    switch (action.type) {
        case CHANGE_PAGE_VIEW:
            return state.charsPageView === 0 ?
                Object.assign({}, state, { charsPageView: 1 }) :
                Object.assign({}, state, { charsPageView: 0 })
        default:
            return state;
    }
}
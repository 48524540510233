import React from 'react';
import ComponentListTitle from './ComponentListTitle';
import ComponentList from './ComponentList';
import '../Common/Colours.css';

const ComponentChapter = ({ strokeNo, usedComponents, onComponentSelect }) => {
    return (
        <div>
            <ComponentListTitle
                strokeNo={strokeNo} />
            <ComponentList
                usedComponents={usedComponents}
                onComponentSelect={onComponentSelect} />
        </div>
    )
}

export default ComponentChapter;
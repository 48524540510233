import React from 'react';
import Comp from '../CompsPage/Comp';

const Char = ({ usedCharacter,
    characterComponents,
    characterRelated,
    onComponentSelect }) => {
    // return an array of Component containers for all components of chosen character
    const compsArray = characterComponents.map(comp => {
        return (
            <Comp
                key={comp.id}
                id={comp.id}
                displayed={comp.display}
                onComponentSelect={onComponentSelect}
            />
        )
    });
    // define bubble for words
    const Word = ({ displayed }) => {
        const wordLink = 'https://www.mdbg.net/chinese/dictionary?page=worddict&wdrst=1&wdqb='.concat(displayed);
        return (
            <a className='Chars-Word'
                href={wordLink}
                target="_blank"
                rel="noopener noreferrer">{displayed}
            </a>
        );
    }
    // make an array out of words
    const wordsArray = characterRelated.map(word => {
        return (
            <Word
                key={word}
                displayed={word}
            />
        )
    })
    // return it all
    return (
        <div className='Chars-CharRow'>
            <div className='Chars-CharBox'>
                <div className='Chars-Char'>
                    {usedCharacter.character.traditional}
                </div>
            </div>
            <div className="Chars-CompBox">
                {compsArray}
            </div>
            <div className="Chars-WordBox">
                {wordsArray}
            </div>
        </div>
    )
}

export default Char;
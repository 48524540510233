import React from 'react';
import Char from './Char'; 

const CharsPageListChapter = ({ strokeNo, usedComponents, usedCharacters, onComponentSelect }) => {
        // for each character, maps only the components from usedComponents 
    // that are present in the character
    const mapCompsToChar = (char) => {
        let characterComponents = [];
            for (let j=0; j<usedComponents.length; j++) {
                if (char.components.traditional.includes(usedComponents[j].id)) {
                    characterComponents.push(usedComponents[j]);
                }
            
        };
        return (characterComponents);
    }
    // creates an array of characters from input (same stroke number)
    const charsArray = usedCharacters.map((i) => {
        return (
            <Char
                key={i.character.traditional}
                usedCharacter={i}
                characterComponents={mapCompsToChar(i)}
                characterRelated={i.related}
                onComponentSelect={onComponentSelect}
            />
        )
    })
    // returns the chapter
      return (
        <div>
            <p className='Chars-Title'>{strokeNo}{' strokes'}</p>
            {charsArray}
        </div>
    )
}

export default CharsPageListChapter;
import React from 'react';
import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers, /* compose */ } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import 'tachyons';
import Root from './Root';

import { selectComp, changePageView } from './reducers';

const logger = createLogger();
const rootReducer = combineReducers({ selectComp, changePageView });
const composeEnhancers = composeWithDevTools({trace: true});
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunkMiddleware)));

// ReactDOM.render(<Provider store = {store}>
//     <App />
// </Provider>, document.getElementById('root'));

ReactDOM.render(<Root store={store} />, document.getElementById('root'))

/* 
ReactDOM.render(<Provider store={store}>
                    <App />
                </Provider>, document.getElementById('root'));
 */
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

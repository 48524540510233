import React from 'react';
import { NavLink } from 'react-router-dom';

const Comp = ({ id, displayed, onComponentSelect }) => {
    return(
            <NavLink className='Comps-Comp'
                to='/selected'
                onClick={() => onComponentSelect(id)}>{displayed}</NavLink>
        )

}

export default Comp;
import React from 'react';
import { CSVLink } from 'react-csv';

const CharsPageOpts = ({
    // expects array of character objects
    usedCharacters,
    // dispatch and property for whether to display chars in a list or a grid
    onViewChange,
    charsPageView
}) => {
    // headers for CSV export
    const headers = [
        { label: 'Character - traditional', key: "character.traditional" }
    ];

    // options bar
    return charsPageView === 0 ? (
        <div className="Options">
            <div className="Options-Switch"
                onClick={() => onViewChange()}>
                {'Switch to grid'}
            </div>
            <CSVLink
                className="Options-ExportCSV"
                headers={headers}
                data={usedCharacters}
                filename={"characters-export.csv"}>
                {'Export to CSV'}
            </CSVLink>
        </div>
    ) : (
            <div className="Options">
                <div className="Options-Switch"
                    onClick={() => onViewChange()}>
                    {'Switch to list'}
                </div>
                <CSVLink
                    className="Options-ExportCSV"
                    headers={headers}
                    data={usedCharacters}
                    filename={"characters-export.csv"}>
                    {'Export to CSV'}
                </CSVLink>
            </div>
        )
}

export default CharsPageOpts;
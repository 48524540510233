import React from 'react';
import { NavLink } from 'react-router-dom';
import {
    Navbar,
    Nav
} from 'react-bootstrap';

const Navigation = ({ selectedComponent
}) => {
    return (
        <Navbar bg="dark" expand="md" variant="dark">
            <Navbar.Brand as={NavLink} to='/home'>Home</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto justify-content-end">
                    <Nav.Link as={NavLink} to='/list-components'>Components</Nav.Link>
                    <Nav.Link as={NavLink} to='/selected'>Selected: {selectedComponent}</Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link href="https://rbl.sk"
                        target="_blank"
                        rel="noopener noreferrer">About</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Navigation;
import React from 'react';
import Component from '../Common/Component';

const ComponentList = ({
    usedComponents,
    onComponentSelect }) => {
    // creates an array of components from input (same stroke number)
    const compsArray = usedComponents.map((comp) => {
        return (
            <Component
                key={comp.id}
                id={comp.id}
                displayed={comp.display}
                onComponentSelect={onComponentSelect}
            />
        )
    })
    // returns the list of components
    return (
        <div>
            {compsArray}
        </div>
    )
}

export default ComponentList;
import {
    SELECT_COMPONENT,
    CHANGE_PAGE_VIEW
} from './constants';

export const setSelected = (comp) => {
    return {
        type: SELECT_COMPONENT,
        payload: comp
    }
}

export const changePageView = () => {
    return {
        type: CHANGE_PAGE_VIEW
    }
}

/* import { CHANGE_SEARCHFIELD,
    REQUEST_ROBOTS_PENDING,
    REQUEST_ROBOTS_SUCCESS,
    REQUEST_ROBOTS_FAILED
} from './constants';

export const setSearchField = (text) => {
    return {
        type: CHANGE_SEARCHFIELD,
        payload: text
    }
}

export const requestRobots = (dispatch) => {
    dispatch({ type: REQUEST_ROBOTS_PENDING });
    fetch('https://jsonplaceholder.typicode.com/users')
        .then(response => response.json())
        .then(data => dispatch({
            type: REQUEST_ROBOTS_SUCCESS,
            payload: data
        }))
        .catch(error => dispatch({
            type: REQUEST_ROBOTS_FAILED,
            payload: error
        }))
} */